<template>
 <div style='width: 100%; height: 100%; position: relative;'>
 
    <div class='transition' :style='getControlStyle()'>
			<div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 12pt; font-weight: bold;width: 100%;'>CHANNEL DASHBOARD 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 9pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        <br/>
		       
		        <span class='SVcontrolLabel'>Channel/Station:</span><br/>
			
			    <GSelectMSimple :options="stations" style='width:100%' v-model="stationIds" @changed='stationChanged' :multiple="true"/>
			    
			    <br/>
			    <span class='SVcontrolLabel'>From date:</span><br/>
			    <InputDatePick :auto="false" @change="dateChanged" v-model="activeDate"/>     
			    <div style="">
				<InputWeekdays fontsize='7pt' v-model="weekdayValue"/>
				</div>
		        <br/>
		        <span class='SVcontrolLabel'>Days to display:</span><br/>
		        <vue-slider @change='val => noOfDaysChanged(val)' :marks="false" :min="1" :max="9" v-model="noOfDays"></vue-slider>	
		        <br/>
			    <span class='dontWrap smallText bold'>Time (in 100ms) for day-Optimisation: {{(Math.round(timing4Opt)/10)}}sec</span>
				<vue-slider :marks="false" :min="0" :max="6000" v-model="timing4Opt"></vue-slider>	
				<br/>
			    
			    <div class="BMSection" :style='getBGStyle2()'>
		              <span class='BMSectionDetail dontWrap90 smallText' ><b>OPTIONS:</b></span>
		        </div>

		        <br/>
				<span class='SVcontrolLabel'>With treatment: </span>
		    	&nbsp;
		    	<app-switch v-model="withTreatment" :checked="withTreatment"/>
			    <hr/>

				<div  class="BMSection" :style='getBGStyle2()'> 
		              <span class='BMSectionDetail dontWrap90 smallText' ><b>ACTIONS</b></span>
		        </div>
			    <button :title="'reload all data'" :disabled='false' class='button myButton' :style='getAppStyle()' @click="reload()"><mdicon name='reload'/></button>
			    <button :title="'run all checks'" :disabled='false' class='button myButton' :style='getAppStyle()' @click="runCheck()">Check</button>
			   
				<br/>
				<br/>
				<br/>
			    <span class='SVcontrolLabel' >Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>

		    </div>

 	</div>
	<div class='transition'  style='float: left; width: 82%; height: 22pt; overflow-y: scroll; '> 
	    <div style='width: 100%; float:top; border: 1pt solid grey;' >
	    	<div class='BMdayH' style='width: 120pt !important; font-size: 9pt; font-weight: bold;'>Channel</div>
	    	
			<span v-for="(day,idx) in validDates(dates,dayStats)" :key="'D'+idx" class='BMdayH' 
			    @contextmenu="contextmenu($event,null,isoDates[day])"
			    style="text-align: middle; width: 110pt !important; margin: 2pt;">
			    <div  :style="'font-size: 9pt; width: 98pt; font-weight: bold;'">
			     {{weekdayLabel[day]}}&nbsp;{{ day}}
			    </div>
			</span>
		</div>
	</div>	
	<div class='transition noBreak' style='float: left; width: 82%; height: calc(82vH); overflow-y: scroll; overflow-x: scroll;'> 
		<div v-for="(iCh,idx) in stationIds" :key="'CH_'+idx" style='width: 100%; float:top; '>
	    	<div class='BMday' @contextmenu.prevent="$refs.editor.open('Media', iCh)" 
	    	           style='position: relative; height: 100%;'>
	    	     <div v-if="stations.find(p=>p.id===iCh).auto.label" class='noBreak' style='width: 120pt !important; font-size: 8pt; height: 15pt; font-weight: normal;'>
	    	     	<div @click="$refs.editor.open('PlaylistAutomation', stations.find(p=>p.id===iCh).auto.id)" class='noBreak' style='cursor: pointer; width: 120pt !important; font-size: 8pt; height: 15pt; font-weight: normal;'>
	    	     
	    	     	{{stations.find(p=>p.id===iCh).auto.label}}
	    	     	
	    	     	</div>
	    	     	
	    	     </div>    
	    	      <div v-else class='noBreak' style='color: red; width: 120pt !important; font-size: 8pt; height: 15pt; font-weight: normal;'>
	    	     	[no settings]
	    	     </div>      
	    	     <div class='noBreak' @click="openScheduleView(iCh, new Date(activeDate).toISOString().split('T')[0])" style='width: 120pt !important; font-size: 9pt; font-weight: bold;'> 
	    	        <img v-if="allStations.find(p=>p.id===iCh).logo" :src="'data:image/png;base64,'+allStations.find(p=>p.id===iCh).logo"/>
	    	        <span v-else>
	    	     	{{stations.find(p=>p.id===iCh).label}}
	    	     	</span>
	    	     </div>
	    	   <div style='position: absolute; bottom: 0;'>
	    	   <button class='myButton button' :style="getAppStyle()"  title="Run optimiser"
		                 style='font-weight: normal; margin: 0pt; height:28px;' 
		                 @click='runOptimiserOnAllDays(iCh)'>
		                 <mdicon  :width="20" name="cart-arrow-down" /></button>
		                 
		       <button class='myButton button' :style="getAppStyle()" title="Run copy-rotation"
		                 style='font-weight: normal; margin: 0pt; height:28px;' 
		                 @click='copyRotationOnAllDays(iCh)'>
		                 <mdicon  :width="20" name="filmstrip-box-multiple" /></button>
		       </div>
	         </div>
	    	
			<span v-for="(day,idx) in validDates(dates,dayStats)" :key="'D'+idx" class='BMday' @contextmenu="contextmenu($event,iCh,isoDates[day])"
			    style="text-align: middle; cursor: pointer; height: 100pt !important; width: 110pt !important; margin: 2pt;">
			    <div class='BMday'  >
			     <div class='action playlist' @click='openDetailP(iCh, isoDates[day])' :style="'font-size: 8pt; height: 20pt !important; padding: 3pt; border: 1pt outset grey; width: 108pt; font-weight: bold; background-color: '+dayStats[iCh][isoDates[day]].colour+';'">
			     	{{dayStats[iCh][isoDates[day]].name}}
			     </div>
			     <div v-if="dayData[iCh]" 
			        :style="'font-size: 10px;'">
			         <div style='min-height: 100pt;' @click="show(dayData[iCh][isoDates[day]], isoDates[day], iCh)">
			             
					     <div v-if="dayData[iCh][isoDates[day]].issue" :style="'color: #000; font-size: 8pt; width: 108pt; font-weight: bold; background-color: '+dayData[iCh][isoDates[day]].severity.colour+';'">
					     	{{dayData[iCh][isoDates[day]].issue.name}}
					     </div>
					     <div v-else-if="stations.find(p=>p.id===iCh).auto.label" :style="'font-size: 8pt; color: #0a0; border-bottom: 1px dotted grey; width: 108pt; font-weight: bold;'">
					     	no issues
					     </div>
					     <div style='padding-top: 2pt; text-align: center;'>
					     <GProgress :height="11" :percentage="100*dayData[iCh][isoDates[day]].bookedSeconds/dayData[iCh][isoDates[day]].capacitySeconds"/>
					     </div>
					     
					     <div :style="'font-size: 10px;'">
					     	Booked: {{printTimeHHMM(dayData[iCh][isoDates[day]].bookedSeconds)}}, 
					     	Total: {{printTimeHHMM(dayData[iCh][isoDates[day]].totalSeconds)}}<br/>
					     	Capacity: {{printTimeHHMM(dayData[iCh][isoDates[day]].capacitySeconds)}}<br/>
					     	Filler/Regular: {{dayData[iCh][isoDates[day]].filler}}/{{dayData[iCh][isoDates[day]].spots-dayData[iCh][isoDates[day]].filler}}
					     </div>
					     <div v-if="dayData[iCh][isoDates[day]].blocker" :style="'font-size: 10px;'">
					     	Blocker {{dayData[iCh][isoDates[day]].blocker}}<br/>
					     </div>
					     <div v-if="dayData[iCh][isoDates[day]].warnings" :style="'font-size: 10px;'">
					     	Warnings {{dayData[iCh][isoDates[day]].warnings}}<br/>
					     </div>
					     <div v-if="dayData[iCh][isoDates[day]].unplaced" :style="'font-size: 10px;'">
					     	Unplaced {{dayData[iCh][isoDates[day]].unplaced}}<br/>
					     </div>
					     <div v-if="dayData[iCh][isoDates[day]].issues" :style="'font-size: 10px;'">
					     	Issues {{dayData[iCh][isoDates[day]].issues}}<br/>
					     </div>
					 </div>
				     <div v-if="dayData[iCh][isoDates[day]].spots && dayStats[iCh][isoDates[day]].canCreatePlaylist" 
				          class='action playlist' @click='getPL(iCh, isoDates[day])'
				          :style="'cursor: pointer; font-size: 10px;'">
				     	<mdicon :width="16" name="file-outline" /> create playlist
				     </div>
			     </div>
			    </div>
			</span>
		</div>

		
		
	</div>
	<ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuLabel>
        <div v-if="contextData.short" style='text-weight: bold; color: #aaa;'>
        {{contextData.day}} @ {{contextData.short}}
        </div>
       
        </ContextMenuLabel>
        <ContextMenuSep/>
        <ContextMenuItem v-if='contextData.channelId' @clicked="$refs.menu.close();$refs.editor.open('Media', contextData.channelId)">
          open {{contextData.channel}}
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData.channelId' @clicked="$refs.menu.close(); openScheduleView(contextData.channelId, new Date(contextData.day).toISOString().split('T')[0])">
          open Ratecard View 
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData.channelId' @clicked="$refs.menu.close(); openAvailsReport(contextData.channelId, new Date(contextData.day).toISOString().split('T')[0])">
          open Avails report 
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if='contextData.channelId' @clicked="$refs.menu.close(); openDetailPlan(contextData.channelId, new Date(contextData.day).toISOString().split('T')[0])">
          open Run-Down Screen 
        </ContextMenuItem>
         <ContextMenuItem v-if='contextData.channelId' @clicked="$refs.menu.close(); openAsrun(contextData.channelId, new Date(contextData.day).toISOString().split('T')[0])">
          open AsRun Screen 
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if='contextData.channelId' @clicked="$refs.menu.close(); copyRotation(contextData.channelId, new Date(contextData.day).toISOString().split('T')[0])">
         Run copy rotation
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData.channelId' @clicked="$refs.menu.close(); runOptimiser(contextData.channelId, new Date(contextData.day).toISOString().split('T')[0])">
         Run Optimiser
        </ContextMenuItem>
        <ContextMenuItem v-if="stations.find(p=>p.id===contextData.channelId)" @clicked="closeCMN(); $refs.editor.open('OptimizerSettings', stations.find(p=>p.id===contextData.channelId).optimizer.id)">
         Open {{tr("Optimizer Settings")}} '{{stations.find(p=>p.id===contextData.channelId).optimizer.label}}'
        </ContextMenuItem>
       
        
        <ContextMenuSep/>
        <ContextMenuItem v-for="(status,idx) in allStates" :key="'s'+idx" @clicked="$refs.menu.close(); setStatus(contextData, 0, status)">
          set this day for all channels to {{status.name}} 
        </ContextMenuItem>
        <ContextMenuSep/>
        <span v-if='contextData.channelId'>
        <ContextMenuItem v-for="(status,idx) in allStates" :key="'s'+idx" @clicked="$refs.menu.close(); setStatus(contextData, contextData.channelId, status)">
          set this day on {{contextData.channel}} to {{status.name}} 
        </ContextMenuItem>
        </span>
        
       </template>
       
    </ContextMenu>
    
	<GConfirm ref='confirm'/>
	<InfoPopUp :time=infoTime :infoType=asText :info=showAsText></InfoPopUp>
	<ShowIssues ref='showDayIssues' 
	    @reload=runCheckSingleDay @optimizer=runOptimiser @copyRotation=copyRotation @playlist=getPL
	    :severities=severities
	    :spots=showSpots
	    :dayStats=dayStatsDay
	    :issues=showIssues
	    :showSeverity= showSeverity
	>
	</ShowIssues>
	<GFWEOpenEditor ref='editor' @update='reload'></GFWEOpenEditor>
	<GridBreakViewer name='gridViewer' ref='gridViewer'/>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbInfo :action=pbAction @action=action></ProgressBar>
 </div>
</template>	
<script>
import {HTTP, HTTPMP, APIURL, PlayListAPI, asrAPI, userAPI, opsAPI, bngAPI, fwAPI, invAPI, reportAPI, setReload, showError, myLocale} from '@/variables.js';
import {store, restore} from '@/runDown.js';
import {printTimeOpt, printTimeHHMM, printTimeMMSS } from '@/basicTimeFN.js';
import { setGoBack } from '@/breadCrumb.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import { tr } from '@/translate.js';
import { openView } from '@/utils.js';
import InputTime from '@/components/inputElements/InputTime2';
import InfoPopUp from '@/components/InfoPopUp';
import Switch from '@/components/Switch';
import ShowIssues from '@/components/misc/ShowIssues';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import GProgress from '@/components/misc/GProgressSmall';

import ContextMenuSep from '@/components/ContextMenuSep';
import GSelectMSimple from '@/components/GSelectMSimple';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import GridBreakViewer from '@/components/GridBreakViewer';
import GConfirm from '@/components/GConfirm';
import vSelect from 'vue-select'
import ProgressBar from '@/components/ProgressBar';
import InputDatePick from '@/components/inputElements/InputDatePick3';
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
import 'w3-css/w3.css';
let $ = JQuery;
var timers = [];
export default {
  name: 'GFW_CHANNEL_COCKPIT',
   
  components : {
    'app-switch': Switch, InfoPopUp, InputDatePick, GConfirm, GSelectMSimple, GridBreakViewer, InputWeekdays, ProgressBar, 
    ContextMenu, ContextMenuItem, ContextMenuSep, ContextMenuLabel, ShowIssues, GProgress
  },
  data () {
    return {
      tr,
      stationIds: [],
      station: {},
      stations: [],
      allStations: [],
      lookForSpot: {},
      lookForLine: {},
      updateLine: {},
      tz: {},
      weekdayValue: 127,
	  user: {},
      noOfDays: 0,
      fontSize: 10,
      dates: [],
      weekdayLabel: [],
      weekdays: [],
      weekdayBITS: [],
      marked: [],
      gridData: [],
      timeSlots: [],
      usage: [],
      capacity: [],
      asText: "",
      showAsText: "",
      infoTime: 0,
      dontSave: false,
      
      dayStatsDay: {},
      showSpots: [],
      showIssues: [],
      showSeverity: [],
      
      withTreatment: false,
      timing4Opt: 80,  
      isoDates: [],
      allStates: [],
	  dayStats: [],
	  dayData: [],
      showDangling: [],
      loadingActive: false,
      selectedTimezone: {},
      selectedStation: {},
      tz2: {},
      diff: 0,
      timezones: [],
      timezoneId: 0,
      activeDate: {},
      dataDay: {},
      severities: [],
      data: [],
      spots: [],
      dayStatus: {},
      controlsRight: false,
      updatedSpots: [],
      clbId: "",
      counter: 0,
	  printTimeHHMM,
	  showProgressBar: false,
      pbAction: "",
      pbInfo: "",
      pbDay: null,
      pbChannelId: 0,
      playlistReq: {},
	  printTimeMMSS, getAppStyle, getBGStyle2
      //
	}
  },
  methods: {
    myLocale() { return myLocale();},
    contextmenu(event, channelId, day){
        event.preventDefault();
        let channel = channelId?this.stations.find(p=>p.id===channelId):"";
        this.$refs.menu.open(event, {day: day, channelId: channelId, channel: channel.label, short: channel.short } )
	},
	closeCMN() { try{ this.$refs.menu.close() } catch(e) { /* */ }},
    getPBOptions(usage, capa)
    {
    	if ( usage == 0 )
    	{
    		return this.PBOptionsZERO;
    	}
    	if ( usage > capa )
    	{
    		return this.PBOptionsRED;
    	}
    	else
    	{
    		return this.PBOptions;
    	}

    },
    getIssues( data, date, channelId)
    {
    	let showSpots= [];
    	let showIssues= [];
    	let showSeverity= [];
        for ( var i = 0; i < data.issueSpots.length; i++ )  
        { 
            showSpots.push( data.issueSpots[i]);
            showIssues.push( data.performed[data.issueFound[i]]);
            showSeverity.push( data.severityFound[i]);
           
        }
		
		this.showSeverity = showSeverity;
		this.showSpots = showSpots;
    	this.showIssues = showIssues;

    },
    show( data, date, channelId)
    {
    	this.getIssues(data, date, channelId);
    	this.dayStatsDay = data.dayStats;
    	let station = this.stations.find(p=>p.id===channelId).label;
        this.$refs.showDayIssues.open( station, channelId, date );
    },
    openEditor(module, id)
    {
        alert(module);
    	//this.$refs.editor.open(module,id)
    },
    runOptimiser( channelId, day)
    {
    	this.pbAction = "runOptimiserInternal";
    	this.pbInfo = "Run optimiser for " + day;
    	this.pbChannelId=channelId;
    	this.pbDay=day;
  	    this.showProgressBar = true;
    },
    runOptimiserInternal( myId, api )
	{
	    let that = this;
		let opt = this.stations.find(p=>p.id===this.pbChannelId).optimizer;
		if ( !opt )
		{
			showError( that.$toast, "", "no optimizer settings defined for "+this.stations.find(p=>p.id===this.pbChannelId).label);
			that.showProgressBar = false;
			return;
		}
		console.log(bngAPI+"/optimizationOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.pbChannelId+"/"+this.pbDay) 
		HTTP.put( bngAPI+"/optimizationOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.pbChannelId+"/"+this.pbDay+"/"+this.timing4Opt+"/"+opt.id+"/"+myId)
            .then( response => 
            { 
            	//that.runCheck();
                
                that.runCheckSingleDay(that.pbChannelId,that.pbDay);
                that.showProgressBar = false;
                
            }).catch(e => {
            	that.showProgressBar = false;
                showError( that.$toast, "run optimiser", e);
            });
	},
	runOptimiserOnAllDays(channelId)
    {
		//alert( channelId)
		
    	this.pbAction = "runOptimiserOnAllDaysInternal";
    	this.pbInfo = "Run optimiser ("+(this.timing4Opt/10)+"sec/day)";
    	this.pbChannelId=channelId;
  	    this.showProgressBar = true;
  	    
    },
    copyRotationOnAllDays(channelId)
    {
		
    	this.pbAction = "copyRotationOnAllDaysInternal";
    	this.pbInfo = "Run rotation";
    	this.pbChannelId=channelId;
  	    this.showProgressBar = true;
  	    
    },
	runOptimiserOnAllDaysInternal(myId, api )
	{
		let that = this;
		let dates = this.validDates(this.dates);
		dates = dates.map(p=>this.isoDates[p]);
		let opt = this.stations.find(p=>p.id===this.pbChannelId).optimizer;
		if ( !opt )
		{
			showError( that.$toast, "", "no optimizer settings defined for "+this.stations.find(p=>p.id===this.pbChannelId).label);
			that.showProgressBar = false;
			return;
		}
		HTTP.put( bngAPI+"/optimizationOnAllDays/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.pbChannelId+"/"+this.timing4Opt+"/"+opt.id+"/"+myId, dates)
            .then( response => 
            { 
            	that.showProgressBar = false;
            	that.runCheck();
            	
                //that.runCheckSingleDay(channelId, day);
	            
                
            }).catch(e => {
            	that.showProgressBar = false;
                showError( that.$toast, "optimizationOnAllDays", e);
            });
	},
	copyRotation( channelId, day)
	{
	    this.startLoader();
		let that = this; 
		console.log(bngAPI+"/copyRotationOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+day) 
		
		HTTP.put( bngAPI+"/copyRotationOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+day+"/0")
            .then( response => 
            { 
                that.runCheckSingleDay(channelId, day)
	            that.stopLoader()
                
            }).catch(e => {
            	that.stopLoader()
                showError( that.$toast, "copyRotation", e);
            });
	},
	copyRotationOnAllDaysInternal(myId, api )
	{
	   
		let that = this; 
		let dates = this.validDates(this.dates);
		dates = dates.map(p=>this.isoDates[p]);
		console.log(bngAPI+"/copyRotationOnAllDays/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.pbChannelId+"/"+myId) 
		HTTP.put( bngAPI+"/copyRotationOnAllDays/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.pbChannelId+"/"+myId, dates)
            .then( response => 
            { 
            	that.showProgressBar = false;
            	that.runCheck();
                
            }).catch(e => {
            	   that.showProgressBar = false;
            	   showError( that.$toast, "copyRotation", e);
            });
	},
    setStatus( context, stationId, status)
    {
    	this.$refs.menu.close()
    	//alert(JSON.stringify(context))
    	let that = this;
    	let statusId = status.id;
    	let date = context.day;
    	//let stationId = context.channelId?context.channelId:0;
    	let stationIds = this.stationIds
    	if ( stationId )
    	{
    		stationIds = stationIds.filter(p=>p.id===stationId);
    	}
    	//alert(invAPI+"/setDayTXStatsForChannels/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+date+"/"+stationId+"/"+statusId +" " + JSON.stringify(this.stationIds))
    	HTTP.put( invAPI+"/setDayTXStatsForChannels/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+date+"/"+stationId+"/"+statusId, stationIds)
        		 .then( response => {
        		      //alert( JSON.stringify(response.data.data))
        		      for ( let chId in response.data.data )
        		      {
        		      	let arr = response.data.data[chId];
        		      	for ( let d in arr )
        		      	{
        		      		this.dayStats[chId][d] = arr[d];
        		      	}
        		      }

                 }).catch(e => {
						showError( that.$toast, "setting day status", e);
                    });
    },
    openDetailP(stationId, isoDay)
    {
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	if (this.dayStats[stationId][isoDay].aired == 0)
    	{
    		openView( this.$router, this.$toast, 'detailPlanning?stationId='+stationId+'&day='+ isoDay);
    	}
    	if (this.dayStats[stationId][isoDay].aired == 1)
    	{
    		openView( this.$router, this.$toast, 'AsRun?stationId='+stationId+'&day='+ isoDay);
    	}
    },
    openDetailPlan(stationId, isoDay)
    {
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	openView( this.$router, this.$toast, 'detailPlanning?stationId='+stationId+'&day='+ isoDay);
    },
    openAsrun(stationId, isoDay)
    {
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	openView( this.$router, this.$toast, 'AsRun?stationId='+stationId+'&day='+ isoDay);
    },
    openAvailsReport(stationId, isoDay)
    {
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	openView( this.$router, this.$toast, 'availsReport?stationId='+stationId+'&day='+ isoDay);
    },
    openScheduleView(stationId, isoDay)
    {
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	openView( this.$router, this.$toast, 'scheduleView?stationId='+stationId+'&day='+ isoDay);
    },
    filter(data)
    {
    	if ( this.selectedPrograms && this.selectedPrograms.length )
    	{
    		return data.filter(p=>this.selectedPrograms.includes(p.content.name))
    	}
    	if ( this.selectedProgCats && this.selectedProgCats.length )
    	{
    		return data.filter(p=>this.selectedProgCats.includes(p.category.name))
    	}
    	return data;
    },

    getDayStats()
    {
    	let that = this;
    	let dateFrom = new Date(this.activeDate).toISOString().split('T')[0];
    	
    	let day = new Date( this.activeDate);
    	day.setDate( day.getDate() + this.noOfDays - 1);
        let dateTo = new Date(day).toISOString().split('T')[0];
        let time = new Date().getTime();
        //alert( invAPI+"/getDayTXStats/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+dateFrom+"/"+dateTo);
    	HTTP.post( invAPI+"/getDayTXStatsForChannels/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateTo, this.stationIds)
        		 .then( response => {
                     this.dayStats = response.data.data;
                     this.allStates = response.data.allStates;
                     this.dayData = [];
                     console.log("getDayStats() took "+(new Date().getTime()-time))
                 }).catch(e => {
						showError( that.$toast, "get day tx status", e);
                    });
    },
    runCheck()
    {
    	this.pbAction = "runCheckInternal";
    	this.pbInfo = "Running TX checks";
  	    this.showProgressBar = true;
    },
    runCheckInternal(myId, api, req, withTreatment) 
    {
    	let that = this;
    	
    	let dateFrom = new Date(this.activeDate).toISOString().split('T')[0];
    	let day = new Date( this.activeDate);
    	day.setDate( day.getDate() + this.noOfDays - 1);
        let dateTo = new Date(day).toISOString().split('T')[0];
       
        //alert( reportAPI+"/runTXChecks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateTo+"/"+this.withTreatment+"/"+myId);
        api.post( reportAPI+"/runTXChecks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateTo+"/"+withTreatment+"/"+myId, this.stationIds)
        		 .then( response => {
        			 if ( withTreatment )
        			 {
        				 this.runCheckInternal(myId, api, req, false); 
        			 }
        			 else
        			 {
	        			 that.showProgressBar = false;
	                     that.$set( that, "dayData", response.data.data);
	                     that.$set( that, "severities", response.data.severity);
        			 }
                     
                 }).catch(e => {
                	    that.showProgressBar = false;
						showError( that.$toast, "TX Checks", e);
						
                    });
    },
    runCheckSingleDay(stationId, date)
    {
    	//alert(stationId +" - "+date)
    	let that = this;
        let runOnDay = new Date(date).toISOString().split('T')[0];
        //alert( reportAPI+"/runTXChecks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+runOnDay+"/"+runOnDay);
    	HTTP.post( reportAPI+"/runTXChecks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+runOnDay+"/"+runOnDay+"/false/0", [stationId])
        		 .then( response => {
                     that.dayData[stationId][date] = response.data.data[stationId][date];
                     that.getIssues(response.data.data[stationId][date], date, stationId);
                     that.dayStatsDay = that.dayData[stationId][date].dayStats;
                 }).catch(e => {
						showError( that.$toast, "loading inventory", e);
                    });
    },

    activeWeekday(day)
    {
    	return this.weekdayValue & this.weekdays[day];
    },
    validDates(dates)
    {
    	let arr = [];
    	for ( let d in dates )
    	{
    		if ( this.activeWeekday( dates[d]) )
    		{	
    			arr.push( dates[d])
    		}
    	}
    	return arr;
    	
    },
    getDates()
    {
    	this.dates = new Array();
    	this.isoDates = new Array();
    	this.weekdays = new Array();
    	this.weekdayBITS = new Array();
    	var bit = 1;
    	for ( var i = 0; i < 7; i++)
    	{
    		this.weekdayBITS[i] = bit;
    		bit *= 2;
    	}
    	//alert( JSON.stringify( this.weekdayBITS));
    	let d = new Date(this.activeDate);
    	let untilDate = new Date(this.activeDate);
    	untilDate.setDate( untilDate.getDate() + this.noOfDays - 1);
    	untilDate.setHours( d.getHours());
    	untilDate.setMinutes( d.getMinutes());
    	untilDate.setSeconds( d.getSeconds());
    	//alert(d);
    	let locale = sessionStorage.locale.replace('_','-');
    	var options = { month: 'numeric', day: 'numeric' };
    	var weekdayLabelARR = [];
      	let wdArr = JSON.parse(sessionStorage.weekdays);
      	for ( var w=1; w < 8; w++ )
      	{
        	let x = wdArr[w];
         	if ( x.length > 2 )
         	{
             	x = x.substring(0,2);
         	}
         	weekdayLabelARR.push( x);    
      	}
      	var lastIso = "";
    	while ( d <= untilDate)
    	{
    	    let dateStr = d.toLocaleString( locale, options);
    		this.dates.push( dateStr );
    		let isoDate = d.toISOString().split('T')[0];
    		if ( isoDate === lastIso)
    		{
    			d.setHours(d.getHours()+4);
    			isoDate = d.toISOString().split('T')[0];
    		}
    		lastIso = isoDate;
    		this.isoDates[dateStr] = isoDate;
    	    this.weekdays[dateStr] = this.weekdayBITS[d.getDay()];
    	    this.weekdayLabel[dateStr] = weekdayLabelARR[d.getDay()];
    	    //alert( d.getDay()+"/"+this.weekdayBITS[d.getDay()]+"::"+ this.weekdays[dateStr]+"-"+dateStr);
    		d.setDate(d.getDate()+1);
    	}
    	return this.dates;
    },
    getCellStyle(grid, day)
    {
    	if ( this.hasAdBreak( grid, day) )
    	{
    		return "cursor: pointer; border-bottom: 1px solid #ddd; width: 35pt;"; // "background-color: #ddd;";
    	}
    	return "visibility: visible; border-bottom: 1px solid #ddd; width: 35pt;";
    },
    
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 18%; height: calc(100vh - 120px); padding: 2ex;"
    },
    stationChanged(  stationId) 
    {
    	this.checkChannels(this.stations);
  		this.getDays();
  		
    },
    dateChanged(newDate) {

    	this.activeDate = new Date( newDate);
    	this.getDays();
    },
    noOfDaysChanged(val) {

    	//this.getDays();
    },
    tzChanged(x) {
        this.timezoneId = x.id;
    	this.getDays();
    },
	processChange()
    {
     	this.doFilterSpots();
    },
    processChangeImmed()
    {
     	this.getDays();
    },
    checkChannels(stations)
    {
    	for ( let channel in stations )
        {
        	let station = stations[channel];
        	station.visible = !this.stationIds.find( p=>p===station.id);
        }    
    },
    userUpdated( user) { 
    	if ( !this.dontSave)
    	{
			let that = this;
			this.user = user  
			this.user.media = this.stationIds;
			
			HTTP.put( userAPI+"/setUserMedia/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, this.user)
	        .then( response => 
	        { 
	            console.log( "CHANGED");
	            console.log( JSON.stringify(this.user.media));
				that.user = response.data;
	
	        }).catch(e => {
	        	that.$toast.error("user update (preferred media) " + e.response.data, 'Error', { position: "topRight" });
	
	        });
    	}
    },
    loadStation(  )
    {
    	
    	let that = this;
    	if ( !this.update )
    	{
    		this.update = new Array();
    	}
    	let activeDate = this.activeDate;
    	
		//this.startLoader()

		that.selectedPrograms = new Array();
    	that.selectedCopies = new Array();
    	that.selectedClients = new Array();
    	that.selectedProducts = new Array();
    	that.selectedStates = new Array();
    	if ( this.selectedTimezone && this.selectedTimezone.id )
        {
       	 	this.timezoneId = this.selectedTimezone.id;
        }

        if ( !this.selectedTimezone  )
        {
       		this.timezoneId = 0;
        }
        
        let time = new Date().getTime();
        let userLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
        		 .then( response => 
                 {
                	 that.user = response.data;
                	 if ( !that.stationIds || ! that.stationIds.length )
                	 {
                	 	that.stationIds = that.user.media;
                	 }
                	 resolve (that.stationIds);
                 }).catch(e => {
                     that.$toast.error("loading user (channelDB): " + e.response.data, 'Error', { position: "topRight" });
                     that.stopLoader();
                     reject ();
                 });
	      });
        userLoad.then( stationIds => {
        	let stationLoad = new Promise(function (resolve, reject) {
        	    //alert(sessionStorage.unitId);
        	    
                console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/allEntities/true/0");
           		HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/allEntities/true/0")
            		 .then( response => 
                     {
                     	
                     	let dur = (new Date().getTime()-time);
                   		console.log("LOADED STATIONS in " + dur +"ms");  
                   		console.log(JSON.stringify(response.data))
                        
                        let allStations = response.data.data;
                        
                        allStations.sort((a, b) => {
    					    let fa = a.name.toLowerCase(),
    					        fb = b.name.toLowerCase();
    					
    					    if (fa < fb) {
    					        return -1;
    					    }
    					    if (fa > fb) {
    					        return 1;
    					    }
    					    return 0;
    					});
                        that.stations = [];
                        let autoSettings = response.data.entity2DataMap.PlaylistChecks;
                        HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/PlaylistAutomation/allEntities/true/0")
               		        .then( response => 
                        {
                        	let allAutomation = response.data.data;
                        	
                        	let optSettings = response.data.entity2DataMap.OptimizerSettings;
                        	for ( let channel in allStations )
                            {
                        		
                            	let station = allStations[channel];
                            	let auto = autoSettings.data.find(p=>p.id==station.playListCheckId);
                            	
                            	let autoFull = allAutomation.find(p=>p.id==station.playListCheckId);
                            	
                            	let optId = autoFull.optimizerSettingsId;
                            	let opt = optSettings.data.find(p=>p.id==optId);
                            	that.stations.push( { "id": station.id, "label": station.name, "short": station.shortname, "visible":true, "auto": auto?auto:{}, "optimizer": opt?opt:{} });
                            	
                            }    
                           
        					//if ( that.stationIds.length == 0)
        					//{
        					//	that.stationIds = that.stations.map( p => p.id);
        					//}
        					that.allStations = allStations;
        					that.checkChannels(that.stations);
        					that.getDayStats();
        					that.runCheck();
        					that.stopLoader();
                            resolve (that.stations); 
                        
                        
                        
                        }).catch(e => {
                            that.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
                            that.stopLoader();
                            reject ();
                        });
                        
                        
                        
                       
                                           
                     }).catch(e => {
                            that.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
                            that.stopLoader();
                            reject ();
                        });
    	      });
        	
        });         
    	
	      
    },
    
    resetFilter() {
        let that = this;
        that.selectedPrograms = new Array();
    	//that.selectedCopies = new Array();
    	that.selectedClients = new Array();
    	that.selectedFamilies = new Array();
    	that.selectedInventories = new Array();
    	//that.selectedStates = new Array();
    	//that.allPrograms = new Array();
    	//that.allCopies = new Array();
    	//that.allClients = new Array();
    	//that.allProducts = new Array();
    },
    

     reload() {
            this.startLoader();
		    this.getDates();
		  	this.loadStation();
		  	
		  },
    forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
	getPL(channelId, date)
  	{
  		this.playlistReq = {"channelId": channelId, "date": date};
  	    this.pbAction = "getPLIntern";
  	    this.pbInfo = "Creating playlist";
  	    this.showProgressBar = true;
  	},
  	action( what, myId, api)
    {
    	this[what](myId, api, this.playlistReq, this.withTreatment)
    },
	getPLIntern(myId, api, playlistReq) 
	{
		let date = playlistReq.date;
		let channelId = playlistReq.channelId;
		let dateStr = new Date(date).toISOString().split('T')[0];
		let that = this;
		//let url = APIURL+PlayListAPI+"/excel/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+dateStr+"/"+myId;
		//console.log( url);
		//window.location.href = url;
		api.post( PlayListAPI+"/createPlaylist/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+dateStr+"/true/"+myId)
					 .then( response => 
	            { 
	               let downloadResponse = response.data;
	               if ( downloadResponse.dayStatus )
	               {
	               		that.dayStats[channelId][date]=downloadResponse.dayStatus;
	               		//that.$emit("statusUpdate", that.myData.date, that.myData.media.id, downloadResponse.dayStatus)
	               }
	               that.showProgressBar = false;
	               
	               that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
	               that.runCheck();

	            }).catch(e => {
	                    that.showProgressBar = false;
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
	           
	},
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#fff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
	   
    },
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  
  created() { 
	  
	this.dontSave = false;  
	if ( this.$route.query.stationId )   
	{
		 this.dontSave = true;
		 this.stationIds = [ parseInt( this.$route.query.stationId )] 
		
	}
    this.activeDate = new Date();
    this.activeDate.setDate( this.activeDate.getDate() +1);
    this.timezoneId = sessionStorage.timezoneId;
    this.noOfDays = 7;
	this.reload();

  },
  watch: {
	  stationIds: function() { this.userUpdated( this.user);},
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>

<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}

.bold {
  font-weight: bold;
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.floatLeft {
    float: left;
}
.spotDetail { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;

  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.uploadArea {
 float: left; 
 width: 82%; 
 height: calc(100vH - 120px);
 border: 1pt dotted #ccc;
}
.reqLineBG {
	border: 2pt solid red !important;
}
.header {
  font-size: 14pt;
  font-weight: bold;
}
.playlist {
 transition: all .4s;
   -webkit-transition: all .4s;
}
.playlist:hover{
	background-color: #ccc;
}
.action {
 transition: all .4s;
   -webkit-transition: all .4s;
}
.action:hover{
	color: #fff;
	font-weoght: bold;
}
.BMday {
  display: table-cell;
  margin: 5pt;
  height: 100pt !important;
  overflow-x: hidden;
  border-right: 1px dotted #ccc;
  border-bottom: 1px solid #00a;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.headerDiv {
  margin: 5pt;
  height: 40pt !important;
  overflow-x: hidden;
  border-right: 1px dotted #ccc;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.BMdayH {
  display: table-cell;
  margin: 5pt;
  height: 20pt !important;
  overflow-x: hidden;
  border-right: 1px dotted #ccc;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.noBreak {
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.reqLineBG2 {
    cursor: pointer;
    color: #00a;
	background-color: #fcf !important;
	//box-shadow: 0pt 2pt 0pt 0 #888;
}
</style> 